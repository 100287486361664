import { useQuery } from "@tanstack/react-query";

import { Me } from "@/TS/Interfaces/global_interfaces";

export const useProfileMe = () => {
  return useQuery({
    queryKey: ["profile-me"],
    queryFn: async () => {
      const response = await fetch(`/api/profile-me`);
      if (!response.ok) {
        throw new Error("Erreur lors de la récupération du profil me");
      }

      const data: Me = await response.json();
      return data;
    },
  });
};
