"use client";

import Image from "next/image";
import { signOut } from "next-auth/react";
import { useContext, useEffect, useState } from "react";
import UserContact from "@/app/(main)/_user_components/user-contact";
import UserContracts from "@/app/(main)/_user_components/user-contracts";
import UserFacture from "@/app/(main)/_user_components/user-facture";
import UserProfileShow from "@/app/(main)/_user_components/user-profile-show";
import { getUserHeaderData } from "@/app/api/dynabuy-back/api-auth";
import CardLink from "@/components/card-link";
import ComponentLoading from "@/components/global/component-loading";
import Icon from "@/components/icon";
import SidebarItem from "@/components/sidebar-item";
import { breadcrumbContext } from "@/context/breadcrumb-context";
import { sidebarContext } from "@/context/sidebar-context";
import { useProfileMe } from "../(club)/hooks/use-profile-me";
export default function UserSidebar() {
  const [user, setUser] = useState(null);
  const {
    val,
    setVal,
    setIsHide
  } = useContext(sidebarContext);
  const [value, setValue] = useContext(breadcrumbContext);

  // Query
  const {
    data: profileMe,
    isLoading,
    error: queryError
  } = useProfileMe();
  useEffect(() => {
    const getUser = async () => {
      const user = await getUserHeaderData();
      setUser(user);
    };
    getUser();
  }, []);
  const logout = () => {
    setVal("");
    setIsHide(false);
    signOut({
      callbackUrl: "/"
    });
  };

  //TODO LES SIDEBAR DOIVENT avoir un href ( c'est pour ça que c'est en rouge )

  return <div className={`right-0 top-0 h-full w-full z-40`} data-sentry-component="UserSidebar" data-sentry-source-file="user-sidebar.tsx">
      {!user && <ComponentLoading></ComponentLoading>}
      <>
        <div className={"relative z-40 ml-auto bg-white w-full md:w-[400px] h-full flex flex-col justify-between overflow-y-auto"}>
          {user && <div className={"m-6 md:m-8 "}>
              <div className={"md:flex-col flex justify-between flex-row-reverse"}>
                <Icon icon={"cross"} size={40} className={"cursor-pointer md:self-end -translate-y-2"} onClick={() => {
              setVal("");
            }} />
                <h4 className={"md:text-2xl text-xl font-bold uppercase md:mb-9 2xl:mb-5"}>
                  Bonjour {user?.firstName} !
                </h4>
              </div>
              <ul className={"md:mb-16 2xl:mb-8"}>
                <li>
                  <SidebarItem href={"#"} icon={"profile"} onClick={() => {
                setVal(<UserProfileShow />);
                setIsHide(false);
              }} title={"Mon profil"} className={"mb-4 md:text-sm hover:cursor-pointer"} />
                </li>
                <li>
                  <SidebarItem icon={"factures"} onClick={() => {
                setVal(<UserFacture />);
              }} title={"Mes factures"} className={"mb-4"} />
                </li>
                <li>
                  {/*TODO HREF à remplacer*/}
                  <SidebarItem
              // href={`/`}
              icon={"contrats"} onClick={() => {
                setVal(<UserContracts />);
              }} title={"Mes contrats et avenants"} className={"mb-4"} />
                </li>
                {/*<li>*/}
                {/*  <SidebarItem*/}
                {/*    href={"#"}*/}
                {/*    icon={"toggle"}*/}
                {/*    onClick={() => {*/}
                {/*      setVal(<UserParameters />);*/}
                {/*      setIsHide(false);*/}
                {/*    }}*/}
                {/*    title={"Mes paramètres"}*/}
                {/*    className={"mb-4 hover:cursor-pointer"}*/}
                {/*  />*/}
                {/*</li>*/}
                <li>
                  <SidebarItem href={`/mes-clubs`} icon={"mes-clubs"} onClick={() => {
                setVal("");
                setIsHide(false);
              }} title={"Mes clubs d'Affaires"} className={"mb-4"} />
                </li>
              </ul>
              <div className="flex w-full md:w-auto mx-auto justify-center">
                <CardLink href={"https://www.rencontres-dirigeants.com/"} title={""} target={"_blank"} contentClassName={"bg-rencontres-blue group"}
            // TODO DEMANDER IMAGE BONNE QUALI
            img={"/img/rencontres-dirigeants.png"} imgAlt={"Rencontres dirigeants"} imgClassName={"w-1/2 min-w-[50%]"} verticalCurveClass={"fill-rencontres-blue stroke-rencontres-blue"} className={"2xl:h-36"}>
                  <div className={"flex flex-col items-center w-full py-10 justify-center"}>
                    <Image src={"/img/RencontresDirigeants.svg"} alt={"Rencontres dirigeants"} className={"my-2 w-full"} width={0} height={0} />
                    <div className={"mt-2 underline text-black text-2xs group-hover:font-bold"}>
                      Voir le site
                    </div>
                  </div>
                </CardLink>
              </div>
            </div>}
          <div>
            <ul className={"flex flex-col"}>
              <li className={" px-8 h-fit"}>
                <SidebarItem href={"#"} icon={"contact"} onClick={() => {
                setVal(<UserContact />);
                setIsHide(false);
              }} title={"Aide & contact"} className={"hover:cursor-pointer  md:my-7 2xl:my-2"} data-sentry-element="SidebarItem" data-sentry-source-file="user-sidebar.tsx" />
              </li>
              {profileMe?.currentlyPresident && <li className={" px-8 h-fit"}>
                  <SidebarItem href={"https://forms.clickup.com/5730789/f/5ewf5-20062/RD6DFVOUSY3LLNE8TP"} icon={"bx-comment-error"} title={"Déclarer un bug sur le site"} className={"hover:cursor-pointer  md:my-7 2xl:my-2"} targetBlanck={true} />
                </li>}
              <div className={"bg-light-grey h-0.5 "} />
              <li className={" px-8"}>
                <SidebarItem href={"#"} icon={"logout"} onClick={() => logout()} title={"Se déconnecter"} className={"cursor-pointer  mt-6 mb-7"} data-sentry-element="SidebarItem" data-sentry-source-file="user-sidebar.tsx" />
              </li>
            </ul>
          </div>
        </div>
      </>
    </div>;
}